/**
 * Stores build information. The string literals are replaced at compile time by `set_version.ts`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2024-09-10",
    versionChannel: "nightly",
    buildDate: "2024-09-10T00:06:04.265Z",
    commitHash: "fac9b33f79d712a93cd3c8bae5b944b0ed6aac0f",
};
